<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <img :src="_image_url" class="img-fluid ounded-bottom" alt="" />

        <b-card-text class="mb-2 text-center">
          <div class="alert alert-danger" role="alert">
            <h4 class="alert-heading">Please verify your email.</h4>
          </div>
        </b-card-text>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    try {
      // await this.$toast("Please verify your email.", {
      //   position: "top-right",
      //   icon: "warning",
      //   type: "error",
      //   timeout: 5000,
      //   closeOnClick: true,
      //   pauseOnFocusLoss: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   draggablePercent: 0.6,
      //   showCloseButtonOnHover: false,
      //   hideProgressBar: true,
      //   closeButton: "button",
      //   icon: true,
      //   rtl: false,
      // })
      const resp = await this.api.get(`/api/users/accessToken`)
      this.$router.push({ name: "call-Setting" })

      console.log(resp)
    } catch (error) {
      console.error(error)
      return ""
    }
  },
  computed: {
    _image_url() {
      return `${process.env.VUE_APP_API_BASEURL}/imageDatas/home800x8001(1).png`
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
